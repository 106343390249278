import React from 'react';
import Select from 'react-select';

const CourseSelector = ({ options, selected, onChange }) => {
    const handleChange = (selectedOptions) => {
        const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
        onChange(selectedValues);
    };

    // Форматуємо дані для React Select
    const formattedOptions = options.map((course) => ({
        value: course.id,
        label: `${course.title} (${course.group}) ${course.price} ${course.course_id}(${course.language})`,
    }));

    // Форматуємо обрані значення
    const formattedSelected = selected
        ? formattedOptions.filter((option) => 
            selected.some((select) => option.value === select.id)
        )
        : [];

    return (
        <div>
            <label>Курси</label>
            <Select
                options={formattedOptions}
                value={formattedSelected}
                onChange={handleChange}
                isMulti // Увімкнення мультіселекту
                placeholder="Оберіть курси..."
            />
        </div>
    );
};

export default CourseSelector;
