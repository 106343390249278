import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Для перенаправлення
import apiClientNoAuth from "../../api";

function AdminLogin() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [msg, setMsg] = useState("");
    const [err, setErr] = useState("");
    const navigate = useNavigate(); // Хук для навігації
    const [loading, setLoading] = useState(false);

    const setAuthCredentials = (data) => {
        localStorage.setItem("authToken", data.token); // Зберігаємо токен у локальному сховищі
        localStorage.setItem("isAuthenticated", "true");
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            setMsg("");
            setErr("");
            const { data } = await apiClientNoAuth.post("/api/auth/login", { email, password });
            setMsg(data.message); // Наприклад, "Успішний вхід"

            setAuthCredentials(data)

            navigate("/admin/dashboard");
        } catch (error) {
            setErr(error.response?.data?.message || "Помилка сервера");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <h2>Admin Login</h2>
            {msg && <div className="alert alert-success">{msg}</div>}
            {err && <div className="alert alert-danger">{err}</div>}

            <form onSubmit={handleLogin}>
                <div>
                    <label>Email</label>
                    <input
                        type="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div>
                    <label>Пароль</label>
                    <input
                        type="password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? <div className="spinner"></div> : "Увійти"}
                </button>
            </form>
        </div>
    );
}

export default AdminLogin;
