import React, { useState, useEffect } from 'react';
import apiClient from '../../api';
import { FaEdit } from "react-icons/fa"; // Іконка редагування

const ShortcodesPage = () => {
    const [shortcodes, setShortcodes] = useState([]);
    const [error, setError] = useState('');
    const [shortcodeForm, setShortcodeForm] = useState({ id: '', code: '', value: '' });
    const [isFormVisible, setIsFormVisible] = useState(false);

    useEffect(() => {
        const fetchShortcodes = async () => {
            try {
                const response = await apiClient.get('/api/shortcodes');
                setShortcodes(response.data);
            } catch (error) {
                setError('Не вдалося завантажити шорткоди');
            }
        };

        fetchShortcodes();
    }, []);

    const handleCreateOrUpdate = async (e) => {
        e.preventDefault();
        try {
            if (shortcodeForm.id) {
                // Редагування
                const response = await apiClient.put(`/api/shortcodes/${shortcodeForm.id}`, shortcodeForm);
                setShortcodes(shortcodes.map((sc) => (sc.id === shortcodeForm.id ? response.data : sc)));
            } else {
                // Створення
                const response = await apiClient.post('/api/shortcodes', shortcodeForm);
                setShortcodes([...shortcodes, response.data]);
            }
            setShortcodeForm({ id: '', code: '', value: '' }); // Очищення форми
            setIsFormVisible(false);
        } catch (error) {
            setError(shortcodeForm.id ? 'Помилка при оновленні шорткоду' : 'Помилка при створенні шорткоду');
        }
    };

    const handleEditClick = (shortcode) => {
        setShortcodeForm({ id: shortcode.id, code: shortcode.code, value: shortcode.value });
        setIsFormVisible(true);
    };

    const handleAddNew = () => {
        setShortcodeForm({ id: '', code: '', value: '' });
        setIsFormVisible(true);
    };

    const handleCancel = () => {
        setShortcodeForm({ id: '', code: '', value: '' });
        setIsFormVisible(false);
    };

    return (
        <div className="container">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2>Шорткоди</h2>
                {!isFormVisible ? (
                    <button className="btn btn-primary" onClick={handleAddNew}>
                        Додати новий шорткод
                    </button>
                ) : null}
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
            {isFormVisible && (
                <form onSubmit={handleCreateOrUpdate}>
                    <div>
                        <label>Код</label>
                        <input
                            type="text"
                            placeholder="Код"
                            value={shortcodeForm.code}
                            onChange={(e) => setShortcodeForm({ ...shortcodeForm, code: e.target.value })}
                            required
                            disabled={!!shortcodeForm.id} // Код не можна змінювати при редагуванні
                        />
                    </div>
                    <div>
                        <label>Значення</label>
                        <input
                            type="text"
                            placeholder="Значення"
                            value={shortcodeForm.value}
                            onChange={(e) => setShortcodeForm({ ...shortcodeForm, value: e.target.value })}
                            required
                        />
                    </div>
                    <div>
                        <button type="submit" className="btn btn-primary">
                            {shortcodeForm.id ? 'Зберегти зміни' : 'Додати'}
                        </button>
                    </div>
                    <div>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleCancel}
                        >
                            Скасувати
                        </button>
                    </div>
                </form>
            )}
            <div>
                <h3>Список шорткодів</h3>
                <ul className="no-indent list">
                    {shortcodes.map((sc) => (
                        <li
                            key={sc.id}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderBottom: '1px solid #ccc',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleEditClick(sc)}
                        >
                            <span>
                                <strong>{sc.code}</strong>: {sc.value}
                            </span>
                            <FaEdit
                                style={{ cursor: 'pointer', color: 'blue', fontSize: "1.5em"  }}
                                onClick={() => handleEditClick(sc)}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ShortcodesPage;
