import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import apiClient from '../../api';
import LanguageSelector from '../LanguageSelector';
import CourseSelector from '../CourseSelector';

const TemplateForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [template, setTemplate] = useState({
        name: '',
        subject: '',
        body: '',
        language: 'en',
        courses: [],
    });
    const [courses, setCourses] = useState([]);
    const [msg, setMsg] = useState('');
    const [err, setErr] = useState('');
    const isEditMode = Boolean(id);

    useEffect(() => {
        const fetchTemplate = async () => {
            if (id) {
                try {
                    const response = await apiClient.get(`/api/templates/${id}`);
                    setTemplate(response.data);
                } catch (error) {
                    setErr(error.response?.data?.message || 'Не вдалося завантажити шаблон');
                }
            }
        };

        const fetchCourses = async () => {
            const response = await apiClient.get('/api/courses');
            setCourses(response.data);
        };

        fetchCourses();
        fetchTemplate();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTemplate((prev) => ({ ...prev, [name]: value }));
    };

    const handleCourseChange = (selected) => {
        const selectedCourses = courses.filter((option) => {
            return selected.includes(option.id)
        })
        setTemplate((prev) => ({ ...prev, courses: selectedCourses }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setMsg('');
            setErr('');
            if (isEditMode) {
                await apiClient.put(`/api/templates/${id}`, template);
                setMsg('Шаблон успішно оновлено!');
            } else {
                await apiClient.post('/api/templates', template);
                setMsg('Шаблон успішно створено!');
            }
            navigate('/admin/templates');
        } catch (error) {
            setErr(error.response?.data?.message || 'Помилка сервера');
        }
    };

    const handleCancel = (e) => {
        e.preventDefault();
        setMsg('');
        setErr('');
        setTemplate({ name: '', subject: '', body: '', language: 'en', courses: [] });
        navigate('/admin/templates');
    };

    return (
        <div className="container">
            <button onClick={() => navigate(-1)} className="btn btn-outline-primary btn-xs">
                Назад
            </button>
            <h2>{isEditMode ? 'Редагування шаблону' : 'Створення шаблону листа'}</h2>
            {msg && <div className="alert alert-success">{msg}</div>}
            {err && <div className="alert alert-danger">{err}</div>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Назва шаблону</label>
                    <input
                        type="text"
                        name="name"
                        value={template.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <LanguageSelector
                    value={template.language}
                    onChange={handleChange}
                />
                <CourseSelector
                    options={courses}
                    selected={template.courses}
                    onChange={handleCourseChange}
                />
                <div>
                    <label>Тема листа</label>
                    <input
                        type="text"
                        name="subject"
                        value={template.subject}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Текст листа</label>
                    <textarea
                        name="body"
                        rows="10"
                        value={template.body}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <button type="submit">{isEditMode ? 'Оновити' : 'Створити'}</button>
                </div>
                <div>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={handleCancel}
                    >
                        Скасувати
                    </button>
                </div>
            </form>
        </div>
    );
};

export default TemplateForm;
