import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "../../api";
import PaginationBlock from '../Common/PaginationBlock';
import dateFormat from "../../constants/dateFormat";

const EmailsList = () => {
    const [emails, setEmails] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [err, setErr] = useState("");
    const [autoRefresh, setAutoRefresh] = useState(true);
    const [filter, setFilter] = useState("");
    const [searchFilter, setSearchFilter] = useState("");
    const navigate = useNavigate();

    const fetchEmails = useCallback(async (options = {}) => {
        const { 
            page = currentPage, 
            filter: activeFilter = filter,
            search = searchFilter 
        } = options;
        
        try {
            const response = await apiClient.get("/api/emails", {
                params: { 
                    page, 
                    filter: activeFilter,
                    search
                }
            });
            setEmails(response.data.emails);
            setTotalPages(response.data.totalPages);
            setErr("");
        } catch (error) {
            setErr(error.response?.data?.message || "Помилка при завантаженні листів");
        }
    }, [currentPage, filter, searchFilter]);

    useEffect(() => {
        fetchEmails();
    }, [currentPage, filter, fetchEmails]);

    useEffect(() => {
        if (!autoRefresh) return;

        const interval = setInterval(() => {
            fetchEmails();
        }, 30000);

        return () => clearInterval(interval);
    }, [autoRefresh, currentPage, filter, fetchEmails]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchEmails({ page });
    };

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
        setCurrentPage(1);
        fetchEmails({ page: 1, filter: newFilter });
    };

    const toggleAutoRefresh = () => {
        setAutoRefresh((prev) => !prev);
    };

    const handleOpenDetails = (email) => {
        navigate(`/admin/emails/${email.id}`);
    };

    // Додаємо функцію для визначення кольору статусу
    const getColorByStatus = (status) => {
        switch (status) {
            case 'FAILED':
                return 'red';
            case 'SENT':
                return 'green';
            default:
                return 'black';
        }
    };

    const handleResetSearch = () => {
        setSearchFilter("");
        setCurrentPage(1);
        fetchEmails({ page: 1, search: "" });
    };

    return (
        <div>
            <h2 className="mb-3">Список листів</h2>
            {err && <div className="alert alert-danger">{err}</div>}

            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="btn-group mb-0" role="group" aria-label="Фільтри емейлів">
                    <button
                        className={`btn btn-outline-primary btn-sm ${filter === "" ? "active" : ""}`}
                        onClick={() => handleFilterChange("")}
                    >
                        All
                    </button>
                    <button
                        className={`btn btn-outline-primary btn-sm ${filter === "SENT" ? "active" : ""}`}
                        onClick={() => handleFilterChange("SENT")}
                    >
                        Sent
                    </button>
                    <button
                        className={`btn btn-outline-primary btn-sm ${filter === "FAILED" ? "active" : ""}`}
                        onClick={() => handleFilterChange("FAILED")}
                    >
                        Failed
                    </button>
                </div>

                <div className="mb-0">
                    <button className="btn btn-secondary btn-sm" onClick={toggleAutoRefresh}>
                        {autoRefresh ? "Зупинити оновлення" : "Включити оновлення"}
                    </button>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex gap-2">
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Email or Title"
                        value={searchFilter}
                        onChange={(e) => {
                            setSearchFilter(e.target.value);
                            setCurrentPage(1);
                            fetchEmails({ page: 1, search: e.target.value });
                        }}
                        style={{ width: '250px' }}
                    />
                    {searchFilter && (
                        <button 
                            className="btn btn-outline-secondary btn-sm" 
                            onClick={handleResetSearch}
                        >
                            ✕
                        </button>
                    )}
                </div>
            </div>

            <ul className="no-indent list">
                {emails.map((email) => (
                    <li 
                        key={email.id}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #ccc',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleOpenDetails(email)}
                    >
                        <div style={{ flex: 8 }}>
                            <div style={{ 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between' 
                            }}>
                                <b>{email.template?.name} ({email.template?.language})</b>
                            </div>
                            <div style={{ 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between' 
                            }}>
                                <small>
                                    {email.recipient}
                                </small>
                                <small className="text-muted">
                                    {dateFormat(email.createdAt)}
                                </small>
                            </div>
                            <small className="text-muted">{email.subject}</small>
                        </div>
                        <span style={{ color: getColorByStatus(email.status), textAlign: 'right', flex: 1 }}>
                            {email.status}
                        </span>
                    </li>
                ))}
            </ul>
            
            <PaginationBlock 
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default EmailsList;