import React, { useState, useEffect } from 'react';
import apiClient from '../../api';
import { FaEdit } from "react-icons/fa"; // Іконка редагування
import UserForm from './UserForm';

const UsersPage = () => {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');
    const [userForm, setUserForm] = useState({ id: '', email: '', password: '', isAdmin: false });
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isUpdated, setIsUpdate] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await apiClient.get('/api/users');
                setUsers(response.data);
            } catch (error) {
                setError('Не вдалося завантажити користувачів');
            }
            setIsUpdate(false);
        };

        fetchUsers();
    }, [isUpdated]);

    const handleCreateOrUpdate = async (e, userForm) => {
        e.preventDefault();
        try {
            const data = {
                email: userForm.email,
                password: userForm.password,
                isAdmin: userForm.isAdmin,
            };
            if (userForm.id) {
                await apiClient.put(`/api/users/${userForm.id}`, data);
            } else {
                await apiClient.post('/api/users', data);
            }
            setError('');
            setIsFormVisible(false);
            setIsUpdate(true);
        } catch (error) {
            setError(userForm.id ? 'Помилка при оновленні користувача' : 'Помилка при створенні користувача');
        }
    };

    const handleEditClick = (user) => {
        setUserForm({ id: user.id, email: user.email, password: '', isAdmin: user.isAdmin });
        setIsFormVisible(true);
    };

    const handleAddNew = () => {
        setUserForm({ id: '', email: '', password: '', isAdmin: false });
        setIsFormVisible(true);
    };

    const handleCancel = () => {
        setUserForm({ id: '', email: '', password: '', isAdmin: false });
        setIsFormVisible(false);
    };

    return (
        <div className="container">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2>Користувачі</h2>
                {!isFormVisible ? (
                    <button className="btn btn-primary" onClick={handleAddNew}>
                        Додати нового користувача
                    </button>
                ) : null}
            </div>
            <div>
                {isFormVisible ? (
                    <UserForm
                        user={userForm}
                        onSubmit={handleCreateOrUpdate}
                        onCancel={handleCancel}
                    />
                ) : null}
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
            <ul className="no-indent list">
                {users.map((user) => (
                    <li
                        key={user.id}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '10px',
                            borderBottom: '1px solid #ccc',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleEditClick(user)}
                    >
                        <span style={{ flex: 4, fontSize: '1.2em' }}>{user.email}</span>
                        <span style={{ flex: 1 }}>{user.isAdmin ? 'Адмін' : 'Користувач'}</span>
                        <div style={{ flex: 0 }}>
                            <FaEdit
                                style={{ cursor: 'pointer', color: 'blue', fontSize: "1.5em", marginRight: '10px' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleEditClick(user);
                                }}
                            />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default UsersPage;