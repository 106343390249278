import React, { useEffect, useState } from "react";
import Select from "react-select"; // React Select
import apiClient from "../api";

const LanguageSelector = ({ value, onChange }) => {
    const [languages, setLanguages] = useState([]);
    const [err, setErr] = useState("");

    useEffect(() => {
        const fetchActiveLanguages = async () => {
            try {
                const response = await apiClient.get("/api/languages/active");
                const formattedLanguages = response.data.map(({ code, name }) => ({
                    value: code,
                    label: `${name} (${code})`,
                }));
                setLanguages(formattedLanguages);
            } catch (error) {
                setErr(error.response?.data?.message || "Не вдалося завантажити активні мови");
            }
        };

        fetchActiveLanguages();
    }, []);

    const handleChange = (selectedOption) => {
        onChange({ target: { name: "language", value: selectedOption?.value || "" } });
    };

    return (
        <div className="language-selector">
            <label>Мова</label>
            {err && <div className="alert alert-danger">{err}</div>}
            <Select
                options={languages}
                value={languages.find((lang) => lang.value === value) || null}
                onChange={handleChange}
                placeholder="Виберіть мову ..."
                isClearable
                isSearchable
                styles={{
                    container: (base) => ({
                        ...base,
                        zIndex: 2, // Встановлюємо вищий пріоритет
                    }),
                    menu: (base) => ({
                        ...base,
                        zIndex: 3, // Забезпечуємо, щоб випадаючий список був над кнопками
                    }),
                }}
            />
        </div>
    );
};

export default LanguageSelector;
