import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import AdminLogin from "./components/Auth/AdminLogin";
import AdminDashboard from "./components/AdminDashboard";
import TemplateForm from "./components/Template/TemplateForm";
import TemplatesList from "./components/Template/TemplatesList";
import TemplateDetails from "./components/Template/TemplateDetails";
import TransactionDetails from "./components/Transaction/TransactionDetails";
import EmailDetails from "./components/Email/EmailDetails";
import CoursesPage from "./components/Course/CoursesPage";
import ShortcodesPage from "./components/Shortcode/ShortcodesPage";
import ConfigPage from "./components/Config/ConfigPage";
import LanguagePage from "./components/Language/LanguagePage";
import UsersPage from "./components/Users/UsersPage";

import ProtectedRoutesGroup from "./components/ProtectedRoutesGroup"; // захист маршрутів


const App = () => {
    return (
        <Router>
            <Navbar />
            <Routes>
                {/* Якщо користувач не авторизований, перенаправляємо на сторінку логіну */}
                <Route path="/" element={<Navigate to="/admin/login" />} />
                <Route path="/admin/login" element={<AdminLogin />} />
                {/* Захищений маршрут для дашборду */}
                <Route element={<ProtectedRoutesGroup />}>
                    <Route path="/admin/dashboard" element={<AdminDashboard />} />
                    <Route path="/admin/templates" element={<TemplatesList />} />
                    <Route path="/admin/templates/:id" element={<TemplateDetails />} />
                    <Route path="/admin/templates/form/" element={<TemplateForm />} />
                    <Route path="/admin/templates/form/:id" element={<TemplateForm />} />
                    <Route path="/admin/transactions/:id" element={<TransactionDetails />} />
                    <Route path="/admin/emails/:id" element={<EmailDetails />} />
                    <Route path="/admin/courses" element={<CoursesPage />} />
                    <Route path="/admin/shortcodes" element={<ShortcodesPage />} />
                    <Route path="/admin/config" element={<ConfigPage />} />
                    <Route path="/admin/languages" element={<LanguagePage />} />
                    <Route path="/admin/users" element={<UsersPage />} />
                </Route>
            </Routes>
        </Router>
    );
};

export default App;
