import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Link для навігації
import { useParams, useNavigate } from "react-router-dom";
import dateFormat from "../../constants/dateFormat"
import apiClient from "../../api";

const EmailDetails = () => {
    const { id } = useParams(); // Отримуємо ID листа з URL
    const navigate = useNavigate();
    const [email, setEmail] = useState(null);
    const [err, setErr] = useState("");

    useEffect(() => {
        const fetchEmailDetails = async () => {
            try {
                const response = await apiClient.get(`/api/emails/${id}`);
                setEmail(response.data);
            } catch (error) {
                setErr(error.response?.data?.message || "Не вдалося завантажити лист");
            }
        };

        fetchEmailDetails();
    }, [id]);

    if (err) {
        return (
            <div className="container">
                <div className="alert alert-danger">{err}</div>
            </div>
        );
    }

    if (!email) {
        return <p>Завантаження...</p>;
    }

    return (
        <div className="container" key={email.id}>
            <button onClick={() => navigate(-1)} className="btn btn-outline-primary btn-xs">
                Назад
            </button>
            <h2>Деталі листа</h2>
            {email.template ? (
                <p><strong>Темплейт:</strong> 
                    <Link to={`/admin/templates/${email.template?.id}`}>
                        {email.template?.name} ({email.template?.language})
                    </Link>
                </p>
            ) : (
                <p><strong>Темплейт:</strong> -//-</p>
            )}
            {email.transaction?.id ? (
                <p><strong>Транзакція:</strong> 
                    <Link to={`/admin/transactions/${email.transaction?.id}`}>
                        {email.transaction?.id}
                    </Link>
                </p>
            ) : (
                <p><strong>Транзакція:</strong> -//-</p>
            )}

            <p><strong>Status:</strong>
                <span className={`badge ${email.status === 'SENT' ? 'bg-success' : 'bg-warning'} m-2 mb-0 mt-0`}>
                    {email.status}
                </span>
            </p>

            <p><strong>Отримувач:</strong></p>
            <div className="email-body-container">
                {email.recipient}
            </div>

            <p><strong>Emails:</strong></p>
            <div className="email-body-container">
                <div>
                    <div>{email.subject}</div>
                    <small className="text-muted">
                        {dateFormat(email.createdAt)}
                    </small>
                </div>
            </div>

            <p><strong>Текст:</strong></p>
            <div 
                className="email-body-container"
                dangerouslySetInnerHTML={{ __html: email.body }}
            />
            {email.error_message && (
                <p><strong>Помилка:</strong> {email.error_message}</p>
            )}
        </div>
    );
};

export default EmailDetails;
