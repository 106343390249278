import React from "react";
import { Link } from "react-router-dom"; // Link для навігації
import LogoutButton from "./Auth/LogoutButton";

const Navbar = () => {

    // Імітація авторизації (замініть на реальну перевірку)
    const isAuthenticated = Boolean(localStorage.getItem("isAuthenticated")); // Використовуйте API або контекст

    return (
        <nav className="header-navigation">
            <div>
                <Link to="/admin/dashboard">Дашборд</Link>
                <Link to="/admin/templates">Темплейти</Link>
                <Link to="/admin/courses">Список курсів</Link>
                <Link to="/admin/shortcodes">Shortcodes</Link>
                <Link to="/admin/config">Конфігурація</Link>
                <Link to="/admin/languages">Мови</Link>
            </div>
            <div>
                <Link to="/admin/users">Користувачі</Link>
                {isAuthenticated ? (
                    <LogoutButton />
                ) : (
                    <Link to="/admin/login">Увійти</Link>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
