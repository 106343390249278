import React from "react";
import apiClientNoAuth from "../../api"; // axios інстанс з withCredentials

function LogoutButton() {
    const handleLogout = async () => {
        try {
            await apiClientNoAuth.post("/api/auth/logout");
            localStorage.removeItem("authToken");
            localStorage.removeItem("isAuthenticated");
            window.location.href = "/admin/login"; // або на вашу головну сторінку
        } catch (error) {
            console.error("Logout error:", error);
        }
    };

    return <button onClick={handleLogout}>Вийти</button>;
}

export default LogoutButton;
