import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom"; // Додаємо Link
import apiClient from "../../api";

const TemplateDetails = () => {
    const { id } = useParams();
    const [template, setTemplate] = useState(null);
    const [languages, setLanguages] = useState([]);
    const navigate = useNavigate();
    const [err, setErr] = useState("");

    useEffect(() => {
        const fetchTemplateDetails = async () => {
            try {
                const response = await apiClient.get(`/api/templates/${id}`);
                setTemplate(response.data);
            } catch (error) {
                setErr(error.response?.data?.message || "Не вдалося завантажити шаблон");
            }
        };
        const fetchLanguages = async () => {
            try {
                const response = await apiClient.get("/api/languages/active");
                const languagesMap = response.data.reduce((acc, lang) => {
                    acc[lang.code] = `${lang.name} (${lang.code})`;
                    return acc;
                }, {});
                setLanguages(languagesMap);
            } catch (error) {
                setErr(error.response?.data?.message || "Не вдалося завантажити активні мови");
            }
        };

        fetchTemplateDetails();
        fetchLanguages();
    }, [id]);

    if (err) {
        return (
            <div className="container">
                <div className="alert alert-danger">{err}</div>
            </div>
        );
    }

    if (!template) {
        return <p>Завантаження...</p>;
    }

    return (
        <div className="container">
            <button onClick={() => navigate(-1)} className="btn btn-outline-primary btn-xs">
                Назад
            </button>
            <h2>Деталі шаблону</h2>
            <p><strong>Назва:</strong> {template.name}</p>
            <p><strong>Мова:</strong> {languages[template.language]}</p>
            <p><strong>Курси:</strong></p>
            {template.courses && template.courses.length > 0 ? (
                <ul style={{ padding: "0", margin: "10px 0" }} className="no-indent list">
                    {template.courses.map((course) => (
                        <li key={course.id}>
                            {course.title} - {course.price}: {course.course_id} ({course.language})
                        </li>
                    ))}
                </ul>
            ) : (
                <p>Курси не додані</p>
            )}
            <p><strong>Тема:</strong> {template.subject}</p>
            <p><strong>Тіло:</strong></p>
            <pre>{template.body}</pre>
            <Link to={`/admin/templates/form/${template.id}`}>Редагувати</Link>
        </div>
    );
};

export default TemplateDetails;
