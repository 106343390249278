import React, { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import apiClient from "../api"; // axios instance

const ProtectedRoutesGroup = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(
        localStorage.getItem("isAuthenticated") === "true"
    ); // Попередня перевірка через localStorage
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const token = localStorage.getItem("authToken");
                if (!token) {
                    throw new Error("Токен відсутній");
                }

                const response = await apiClient.get("/api/auth/me", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                setIsAuthenticated(false);
                localStorage.removeItem("authToken");
                localStorage.removeItem("isAuthenticated");
            } finally {
                setLoading(false);
            }
        };

        // Перевіряємо лише якщо користувач попередньо авторизований
        if (isAuthenticated) {
            checkAuth();
        } else {
            setLoading(false);
        }
    }, [isAuthenticated]);

    if (loading) {
        return <p>Завантаження...</p>; // Можна додати спінер
    }

    if (!isAuthenticated) {
        return <Navigate to="/admin/login" replace />;
    }

    return <Outlet />;
};

export default ProtectedRoutesGroup;
