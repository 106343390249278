import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css"; // Існуючий файл
import "./styles.css"; // Новий файл
import "bootstrap/dist/css/bootstrap.min.css";
import App from './app';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
