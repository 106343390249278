import axios from 'axios';

const endpoint = process.env.REACT_APP_API_URL || 'http://localhost:3000';

const apiClient = axios.create({
  baseURL: endpoint, // адреса вашого Node.js сервера
  headers: {
    "Content-Type": "application/json",
    Authorization: `Basic ${localStorage.getItem("authToken") || ""}`,
  },
  withCredentials: true, // Якщо потрібна підтримка cookie
});

// Додаємо токен у заголовок
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("authToken");
  if (token) {
      config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const apiClientNoAuth = axios.create({
  baseURL: endpoint, // адреса вашого Node.js сервера
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // Якщо потрібна підтримка cookie
});

export default apiClient;
