import React, { useState, useEffect } from 'react';
import apiClient from '../../api';
import LanguageSelector from "../LanguageSelector";

const CourseForm = ({ course, onSave, onCancel }) => {
    const [error, setError] = useState('');
    const [courseForm, setCourseForm] = useState({
        id: '',
        course_id: '',
        language: '',
        title: '',
        price: '',
        group: '',
    });
    const [groupOptions, setGroupOptions] = useState([]);

    useEffect(() => {
        if (course) {
            setCourseForm({
                id: course.id,
                course_id: course.course_id,
                language: course.language,
                title: course.title,
                price: course.price,
                group: course.group,
            });
            setError('');
        } else {
            setCourseForm({
                id: '',
                course_id: '',
                language: '',
                title: '',
                price: '',
                group: '',
            });
        }
    }, [course]);

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await apiClient.get('/api/courses', {
                    params: { groupByGroup: '1' }
                });
                setGroupOptions(response.data.map(item => item.group));
            } catch (error) {
                console.error("Помилка при завантаженні груп:", error);
            }
        };
        fetchGroups();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = {
                course_id: courseForm.course_id,
                language: courseForm.language,
                title: courseForm.title,
                price: courseForm.price,
                group: courseForm.group,
            };
            if (course?.id) {
                await apiClient.put(`/api/courses/${course.id}`, data);
            } else {
                await apiClient.post('/api/courses', data);
            }
            setError('');
            onSave();
        } catch (error) {
            setError(error.response?.data?.message || 'Помилка при збереженні курсу');
        }
    };

    const handleCancel = (e) => {
        e.preventDefault();
        setError('');
        if (onCancel) onCancel();
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label htmlFor="group">Група</label>
                <input
                    type="text"
                    id="group"
                    name="group"
                    value={courseForm.group}
                    onChange={(e) =>
                        setCourseForm({ ...courseForm, group: e.target.value })
                    }
                    list="groupOptions"
                />
                <datalist id="groupOptions">
                    {groupOptions.map((grp, index) => (
                        <option key={index} value={grp} />
                    ))}
                </datalist>
            </div>
            <div>
                <label htmlFor="title">Назва курсу</label>
                <input
                    type="text"
                    id="title"
                    name="title"
                    value={courseForm.title}
                    onChange={(e) =>
                        setCourseForm({ ...courseForm, title: e.target.value })
                    }
                    required
                />
            </div>
            <div>
                <label htmlFor="price">Ціна курсу</label>
                <input
                    type="text"
                    id="price"
                    name="price"
                    value={courseForm.price || ""}
                    onChange={(e) => setCourseForm({ ...courseForm, price: e.target.value })}
                    required
                />
            </div>
            <div>
                <label htmlFor="course_id">Course ID</label>
                <input
                    type="text"
                    id="course_id"
                    name="course_id"
                    value={courseForm.course_id}
                    onChange={(e) =>
                        setCourseForm({ ...courseForm, course_id: e.target.value })
                    }
                    required
                />
            </div>
            <LanguageSelector
                value={courseForm.language}
                onChange={(e) =>
                    setCourseForm({ ...courseForm, language: e.target.value })
                }
            />
            {error && <div className="alert alert-danger">{error}</div>}
            <div>
                <button type="submit" className="btn btn-primary">
                    {course?.id ? 'Оновити' : 'Створити'}
                </button>
            </div>
            <div>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleCancel}
                >
                    Скасувати
                </button>
            </div>
        </form>
    );
};

export default CourseForm;
