import React, { useState, useEffect } from "react";
import apiClient from "../../api";

const LanguagePage = () => {
    const [languages, setLanguages] = useState([]);
    const [filteredLanguages, setFilteredLanguages] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [filter, setFilter] = useState("all");
    const [err, setErr] = useState("");

    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const response = await apiClient.get(`/api/languages`);
                setLanguages(response.data);
                setFilteredLanguages(response.data); // Ініціалізуємо фільтрований список
            } catch (error) {
                setErr(error.response?.data?.message || "Не вдалося завантажити список мов");
            }
        };

        fetchLanguages();
    }, []);

    // Фільтрація списку мов
    useEffect(() => {
        let filtered = [...languages];

        // Фільтр по активності
        if (filter === "active") {
            filtered = filtered.filter((language) => language.isActive);
        } else if (filter === "inactive") {
            filtered = filtered.filter((language) => !language.isActive);
        }

        // Пошук по коду або назві
        if (searchQuery) {
            filtered = filtered.filter(
                (language) =>
                    language.code.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    language.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setFilteredLanguages(filtered);
    }, [languages, searchQuery, filter]);

    // Обробка кліку для активації/деактивації мови
    const toggleActiveStatus = async (language) => {
        try {
            await apiClient.patch(`/api/languages/${language.code}`, {
                isActive: !language.isActive,
            });

            setLanguages((prevLanguages) =>
                prevLanguages.map((lang) =>
                    lang.code === language.code ? { ...lang, isActive: !lang.isActive } : lang
                )
            );
        } catch (error) {
            setErr(error.response?.data?.message || "Помилка при оновленні статусу мови");
        }
    };

    return (
        <div className="container">
            <h2>Мови</h2>
            {err && <div className="alert alert-danger">{err}</div>}

            <div className="filters mb-3">
                <input
                    type="text"
                    placeholder="Шукати за кодом або назвою"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="form-control mb-2"
                />
                <div className="btn-group">
                    <button
                        className={`btn btn-outline-primary ${filter === "all" ? "active" : ""}`}
                        onClick={() => setFilter("all")}
                    >
                        Усі
                    </button>
                    <button
                        className={`btn btn-outline-primary ${filter === "active" ? "active" : ""}`}
                        onClick={() => setFilter("active")}
                    >
                        Активні
                    </button>
                    <button
                        className={`btn btn-outline-primary ${filter === "inactive" ? "active" : ""}`}
                        onClick={() => setFilter("inactive")}
                    >
                        Неактивні
                    </button>
                </div>
            </div>

            <div style={{ minWidth: "400px" }}>
                {filteredLanguages.length > 0 ? (
                    filteredLanguages.map(({ code, name, isActive }) => (
                        <div
                            key={code}
                            className={`language-list ${isActive ? "active" : ""}`}
                            onClick={() => toggleActiveStatus({ code, isActive })}
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "10px",
                                border: "1px solid #ccc",
                                marginBottom: "5px",
                            }}
                        >
                            <span className="language-code">{code}</span>
                            <span className="language-name">{name}</span>
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: isActive ? "green" : "red",
                                }}
                            >
                                {isActive ? "Активна" : "Неактивна"}
                            </span>
                        </div>
                    ))
                ) : (
                    <p>Список мов порожній</p>
                )}
            </div>
        </div>
    );
};

export default LanguagePage;
