import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Link для навігації
import apiClient from "../../api";
import { FaEdit } from "react-icons/fa"; // Іконка редагування
import pluralize from 'pluralize';

const TemplatesList = () => {
    const [templates, setTemplates] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [err, setErr] = useState("");
    const [filter, setFilter] = useState("");
    const [groupByTitle, setGroupByTitle] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await apiClient.get("/api/templates", {
                    params: { filter, groupByTitle },
                });
                setErr("");
                setTemplates(response.data);
            } catch (error) {
                setErr(error.response?.data?.message || "Не вдалося завантажити шаблони");
            }
        };
        const fetchLanguages = async () => {
            try {
                const response = await apiClient.get("/api/languages/active");
                const languagesMap = response.data.reduce((acc, lang) => {
                    acc[lang.code] = `${lang.name} (${lang.code})`;
                    return acc;
                }, {});
                setErr("");
                setLanguages(languagesMap);
            } catch (error) {
                setErr(error.response?.data?.message || "Не вдалося завантажити активні мови");
            }
        };

        fetchTemplates();
        fetchLanguages();
    }, [filter, groupByTitle]);

    const handleFilterChange = (newFilter) => {
        setErr("");
        setGroupByTitle(0);
        setFilter(newFilter);
    };

    const handleGroupByChange = (value) => {
        setErr("");
        setFilter("");
        setGroupByTitle(value);
    };
    const handleAddNew = () => {
        navigate("/admin/templates/form");
    };

    const handleResetFilters = () => {
        setErr("");
        setFilter("");
        setGroupByTitle(0);
    };

    return (
        <div className="container">
            <h2>Список шаблонів листів</h2>
            
            {err && <div className="alert alert-danger">{err}</div>}
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="btn-group mb-0" role="group" aria-label="Фільтри">
                    <button
                        className={`btn btn-outline-primary ${!filter && !groupByTitle ? "active" : ""}`}
                        onClick={handleResetFilters}
                    >
                        Всі
                    </button>
                    <button
                        className={`btn btn-outline-primary ${filter === "noCourses" ? "active" : ""}`}
                        onClick={() => handleFilterChange("noCourses")}
                    >
                        Без курсів
                    </button>
                    <button
                        className={`btn btn-outline-primary ${filter === "withCourses" ? "active" : ""}`}
                        onClick={() => handleFilterChange("withCourses")}
                    >
                        З курсами
                    </button>
                    <button
                        className={`btn btn-outline-primary ${groupByTitle === 1 ? "active" : ""}`}
                        onClick={() => handleGroupByChange(1)}
                    >
                        Групувати за назвою
                    </button>
                </div>
                <div>
                    <button className="btn btn-primary" onClick={handleAddNew}>
                        Додати новий шаблон
                    </button>
                </div>
                
            </div>
            <ul className="no-indent list">
                {templates.map((template) => (
                    <li key={template.id} className="template-item">
                        <div className="template-info">
                            {!groupByTitle ? (
                                <Link to={`/admin/templates/${template.id}`} className="template-name">
                                    {template.name}
                                </Link>
                            ) : (
                                template.name
                            )}
                            {!groupByTitle ? 
                                (template.courses && template.courses.length > 0 ? (
                                    <span style={{ fontSize: '10px' }}>
                                        {template.courses.map((course) => (
                                            <div key={course.id}>
                                                <b>{course.price} {course.course_id} ({course.language})</b>
                                            </div>
                                        ))}
                                    </span>
                                ) : (
                                    <span style={{ color: 'red', }}>Курси не додані</span>
                                ))
                            : (` - ${template.count} ${pluralize('template', template.count)}`)
                            }
                            <span className="template-language">{languages[template.language]}</span>
                        </div>
                        {!groupByTitle && (
                            <Link to={`/admin/templates/form/${template.id}`} className="edit-icon">
                                <FaEdit style={{ cursor: 'pointer', color: 'blue', fontSize: "1.5em"  }} />
                            </Link>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TemplatesList;
