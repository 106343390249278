import React, { useState, useEffect } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const UsersForm = ({ user, onSubmit, onCancel }) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [error, setError] = useState('');
    const [userForm, setUserForm] = useState({
        id: user.id,
        email: user.email,
        password: '',
        isAdmin: user.isAdmin,
    });

    useEffect(() => {
        if (user) {
            setUserForm({
                id: user.id,
                email: user.email,
                password: '',
                isAdmin: user.isAdmin,
            });
        }
    }, [user]);
    
    return (
        <form onSubmit={(e) => onSubmit(e, userForm)}>
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input 
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={userForm.email}
                    onChange={(e) => setUserForm({ ...userForm, email: e.target.value })}
                    required
                />
            </div>
            <div className="mb-3">
                <label htmlFor="password" className="form-label">Пароль</label>
                <div className="input-group">
                    <input 
                        type={passwordVisible ? 'text' : 'password'}
                        className="form-control"
                        id="password"
                        name="password"
                        value={userForm.password}
                        onChange={(e) => setUserForm({ ...userForm, password: e.target.value })}
                    />
                    <span className="input-group-text" style={{ cursor: 'pointer' }} onClick={() => setPasswordVisible(!passwordVisible)}>
                        {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </span>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="isAdmin" className="form-label">Адмін</label>
                <input 
                    type="checkbox"
                    className="form-check-input"
                    id="isAdmin"
                    name="isAdmin"
                    checked={userForm.isAdmin}
                    onChange={(e) => setUserForm({ ...userForm, isAdmin: e.target.checked })}
                />
            </div>
            <div>
                <button type="submit" className="btn btn-primary">
                    {userForm.id ? 'Оновити' : 'Створити'}
                </button>
            </div>
            <div>
                <button type="button" className="btn btn-secondary" onClick={onCancel}>
                    Скасувати
                </button>
            </div>
        </form>
    )
}

export default UsersForm;