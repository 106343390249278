import React from 'react';
import TransactionsList from "./Transaction/TransactionsList";
import EmailsList from "./Email/EmailsList"; // Створіть компонент для списку листів

const AdminDashboard = () => {
    return (
        <div className="dashboard-container">
            <h1>Dashboard</h1>
            <div className="dashboard-columns" style={{ display: 'flex', gap: '20px' }}>
                <div className="column" style={{ flex: 1 }}>
                    <TransactionsList />
                </div>
                <div className="column" style={{ flex: 1 }}>
                    <EmailsList />
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;
