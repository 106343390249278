import React, { useState, useEffect } from 'react';
import apiClient from '../../api';
import { FaEdit, FaCopy } from "react-icons/fa";
import pluralize from 'pluralize';

const CoursesList = ({ onEdit, onDuplicate, refreshCourses, handleAddNew }) => {
    const [courses, setCourses] = useState([]);
    const [groupByGroup, setGroupByGroup] = useState(0);
    const [filterGroup, setFilterGroup] = useState("");
    const [filterCourseId, setFilterCourseId] = useState(""); // New state for course_id filter
    const [error, setError] = useState('');

    const fetchCourses = async (options = {}) => {
        const { groupByGroup = 0, group = "", courseId = "" } = options;
        try {
            const response = await apiClient.get("/api/courses", {
                params: { 
                    groupByGroup, 
                    group: group || undefined,
                    courseId: courseId || undefined // Pass courseId to the API
                },
            });
            setCourses(response.data);
        } catch (error) {
            setError(error.response?.data?.message || 'Помилка при завантаженні курсів');
        }
    };

    useEffect(() => {
        fetchCourses({ groupByGroup, group: filterGroup, courseId: filterCourseId });
    }, [groupByGroup, filterGroup, filterCourseId, refreshCourses]);

    const handleResetFilters = () => {
        setGroupByGroup(0);
        setFilterGroup("");
        setFilterCourseId(""); // Reset course_id filter
        fetchCourses({ groupByGroup: 0, group: "" });
    };

    const handleCourseIdChange = (e) => {
        setFilterCourseId(e.target.value);
    };

    // Агрегація курсів за групами, якщо groupByGroup встановлено
    const uniqueGroups = groupByGroup ? Object.values(courses.reduce((acc, course) => {
        if (!acc[course.group]) {
            acc[course.group] = { group: course.group, count: course.count || 1 };
        } else {
            acc[course.group].count += course.count || 1;
        }
        return acc;
    }, {})) : [];

    return (
        <div>
            <h3>Список {filterGroup ? filterGroup : 'курсів'}</h3>
            
            {error && <div className="alert alert-danger">{error}</div>}

            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="btn-group mb-0" role="group" aria-label="Фільтри">
                    <button
                        className={`btn btn-outline-primary ${groupByGroup === 0 ? "active" : ""}`}
                        onClick={() => setGroupByGroup(0)}
                    >
                        Всі курси
                    </button>
                    <button
                        className={`btn btn-outline-primary ${groupByGroup === 1 ? "active" : ""}`}
                        onClick={() => setGroupByGroup(1)}
                    >
                        Порахувати за назвою
                    </button>
                    <button
                        className="btn btn-outline-danger"
                        onClick={handleResetFilters}
                    >
                        Скинути фільтри
                    </button>
                </div>
                <button className="btn btn-primary" onClick={handleAddNew}>
                    Додати новий курс
                </button>
            </div>

            {/* Course ID Filter Input */}
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Фільтрувати за Course ID"
                    value={filterCourseId}
                    onChange={handleCourseIdChange}
                />
            </div>

            {groupByGroup ? (
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Група</th>
                            <th>Кількість курсів</th>
                            <th>Дії</th>
                        </tr>
                    </thead>
                    <tbody>
                        {uniqueGroups.map((groupItem) => (
                            <tr key={groupItem.group} 
                                style={{ cursor: 'pointer' }} 
                                onClick={() => { 
                                    setGroupByGroup(0); 
                                    setFilterGroup(groupItem.group); 
                                }}
                            >
                                <td>{groupItem.group}</td>
                                <td>{groupItem.count} {pluralize('course', groupItem.count)}</td>
                                <td></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th width="10%">Група</th>
                            <th width="40%">Назва</th>
                            <th>Ціна</th>
                            <th>Course ID</th>
                            <th width="60px">Мова</th>
                            <th width="75px">Дії</th>
                        </tr>
                    </thead>
                    <tbody>
                        {courses.map((course) => (
                            <tr key={course.id} 
                                style={{ 
                                    cursor: 'pointer'
                                }} 
                                onClick={() => {
                                    setGroupByGroup(0);
                                    setFilterGroup(course.group);
                                }}
                            >
                                <td>{course.group}</td>
                                <td>{course.title}</td>
                                <td>{course.price}</td>
                                <td>{course.course_id}</td>
                                <td>{course.language}</td>
                                <td>
                                    <FaEdit
                                        style={{ cursor: 'pointer', color: 'blue', fontSize: '1.5em', marginRight: '10px' }}
                                        onClick={(e) => { e.stopPropagation(); onEdit(course); }}
                                    />
                                    <FaCopy
                                        style={{ cursor: 'pointer', color: 'green', fontSize: '1.5em' }}
                                        onClick={(e) => { e.stopPropagation(); onDuplicate(course); }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default CoursesList;
