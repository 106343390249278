import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom"; // Link для навігації
import dateFormat from "../../constants/dateFormat"
import apiClient from "../../api";
import PaginationBlock from '../Common/PaginationBlock';

const TransactionsList = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [err, setErr] = useState("");
  const [filter, setFilter] = useState("");
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [searchFilter, setSearchFilter] = useState("");
  const navigate = useNavigate();

  const fetchTransactions = useCallback(async (options = {}) => {
    const { 
      page = currentPage, 
      filter: activeFilter = filter, 
      search = searchFilter,
    } = options;

    try {
      const response = await apiClient.get("/api/transactions", {
        params: { 
          page, 
          filter: activeFilter, 
          search, 
          sort: 'desc', 
        },
      });
      setTransactions(response.data.transactions);
      setTotalPages(response.data.totalPages);
      setErr("");
    } catch (error) {
      setErr(error.response?.data?.message || "Помилка при завантаженні транзакцій");
    }
  }, [currentPage, filter, searchFilter]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  useEffect(() => {
    if (!autoRefresh) return;

    const interval = setInterval(() => {
      fetchTransactions();
    }, 30000); // Оновлюємо кожні 30 секунд

    return () => clearInterval(interval);
  }, [autoRefresh, fetchTransactions]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchTransactions({ page });
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1); // Скидаємо на першу сторінку після зміни фільтра
    fetchTransactions({ page: 1, filter: newFilter });
  };

  const toggleAutoRefresh = () => {
    setAutoRefresh((prev) => !prev);
  };

  const handleOpenDetails = (transaction) => {
    navigate(`/admin/transactions/${transaction.id}`);
  };

  const handleResetSearch = () => {
      setSearchFilter("");
      setCurrentPage(1);
      fetchTransactions({ page: 1, search: "" });
  };

  const getColorByAction = (item) => {
    switch (item?.action) {
      case 'Waiting':
        return 'blue';
      case 'Blocked':
        return 'red';
      default:
        return 'green';
    }
  }

  return (
    <div>
      <h2 className="mb-3">Список транзакцій</h2>
      {err && <div className="alert alert-danger">{err}</div>}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="btn-group mb-0" role="group" aria-label="Фільтри транзакцій">
          <button
            className={`btn btn-outline-primary btn-sm ${filter === "" ? "active" : ""}`}
            onClick={() => handleFilterChange("")}
          >
            All
          </button>
          <button
            className={`btn btn-outline-primary btn-sm ${filter === "sentEmails" ? "active" : ""}`}
            onClick={() => handleFilterChange("sentEmails")}
          >
            Sent
          </button>
          <button
            className={`btn btn-outline-primary btn-sm ${filter === "unsentEmails" ? "active" : ""}`}
            onClick={() => handleFilterChange("unsentEmails")}
          >
            Waiting
          </button>
          <button
            className={`btn btn-outline-primary btn-sm ${filter === "blocked" ? "active" : ""}`}
            onClick={() => handleFilterChange("blocked")}
          >
            Blocked
          </button>
          <button
            className={`btn btn-outline-primary btn-sm ${filter === "noTemplate" ? "active" : ""}`}
            onClick={() => handleFilterChange("noTemplate")}
          >
            No template
          </button>
        </div>
        <div>
          <button className="btn btn-secondary btn-sm" onClick={toggleAutoRefresh}>
            {autoRefresh ? "Зупинити оновлення" : "Включити оновлення"}
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex gap-3">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Product_id or Transaction_id or Email"
            value={searchFilter}
            onChange={(e) => {
              setSearchFilter(e.target.value);
              setCurrentPage(1);
              fetchTransactions({ page: 1, search: e.target.value });
            }}
            style={{ width: '250px' }}
          />
          {searchFilter && (
              <button 
                  className="btn btn-outline-secondary btn-sm" 
                  onClick={handleResetSearch}
              >
                  ✕
              </button>
          )}
        </div>
      </div>
      <ul className="no-indent list">
        {transactions.map((transaction) => (
          <li
            key={transaction.id}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px solid #ccc',
              cursor: 'pointer',
            }}
            onClick={() => handleOpenDetails(transaction)}
          >
            <div style={{ flex: 6 }}>
              <div className="text-muted" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
                <strong>{transaction.amount / 100} {transaction.currency}</strong>
                <b>{transaction?.stripePaymentId}</b>
              </div>
              <div className="text-muted" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
                <small>
                  {transaction.metadata['customer_email']}
                </small>
                <small>
                  {dateFormat(transaction.createdAt)}
                </small>
              </div>
              <div className="text-muted" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
                <small>{transaction.metadata['product_id']} ({transaction.metadata['lang']})</small>
              </div>
            </div>
            <span style={{ color: `${getColorByAction(transaction)}`, textAlign: 'right', flex: 1 }}>{transaction.action}</span>
          </li>
        ))}
      </ul>
      <PaginationBlock 
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default TransactionsList;
