import React, { useState, useEffect } from "react";
import apiClient from "../../api";
import { Modal, Button } from "react-bootstrap";
import { FaEdit, FaRegTrashAlt } from "react-icons/fa"; // Іконка редагування

const CONFIG_DESCRIPTIONS = {
    'process_transactions': 'example: true/false\n' + 
        'Увімкнути/вимкнути обробку транзакцій',
    'transaction_limit': 'Кількість транзакцій, які обробляються за один раз',
    'emails_limit': 'Кількість листів, які відправляються за один раз',
    'cron_interval_transactions': 'Інтервал перевірки нових транзакцій (cron формат)\n' + 
        'Формат: * * * * *\n' +
        '(хвилини 0-59) (години 0-23) (день місяця 1-31) (місяць 1-12) (день тижня 0-6)\n' +
        'Приклади:\n' +
        '*/1 * * * * - кожну хвилину\n' +
        '*/30 * * * * - кожні 30 хвилин\n' +
        '0 * * * * - кожну годину',
    'process_emails': 'example: true/false\n' + 
        'Увімкнути/вимкнути відправку листів',
    'cron_interval_emails': 'Інтервал відправки листів (cron формат)\n' +
        'Формат: * * * * *\n' +
        '(хвилини 0-59) (години 0-23) (день місяця 1-31) (місяць 1-12) (день тижня 0-6)\n' +
        'Приклади:\n' +
        '*/1 * * * * - кожну хвилину\n' +
        '*/30 * * * * - кожні 30 хвилин\n' +
        '0 * * * * - кожну годину',
    'default_language': 'Мова за замовчуванням',
    'test_mode': 'example: true/false\n' + 
        'Режим тестування (всі листи відправляються на тестову адресу)',
    'test_email': 'Email для тестування',
    'ending_before': 'ID останньої обробленої Stripe транзакції',
    'is_stripe_webhook_enabled': 'example: true/false\n' + 
        'Увімкнути/вимкнути webhook для Stripe'
};

const ConfigPage = () => {
    const [configs, setConfigs] = useState([]);
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [err, setErr] = useState("");
    const [msg, setMsg] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [deleteKey, setDeleteKey] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(false);

    useEffect(() => {
        const fetchConfigs = async () => {
            try {
                const response = await apiClient.get("/api/configs");
                setConfigs(response.data);
            } catch (error) {
                setErr(error.response?.data?.message || "Помилка при завантаженні конфігурацій");
            }
        };

        fetchConfigs();
    }, []);

    useEffect(() => {
        if (isFormVisible) {
            document.getElementById('configForm')?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [isFormVisible]);

    const handleSave = async (e) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            await apiClient.put("/api/configs", { key, value });
            setConfigs((prevConfigs) => {
                const updatedConfigs = prevConfigs.filter((config) => config.key !== key);
                return [...updatedConfigs, { key, value }];
            });
            setKey("");
            setValue("");
            setErr("");
            setMsg("Зміни збережено успішно!");
            setTimeout(() => setMsg(""), 3000);
            setIsEditing(false);
            setIsFormVisible(false);
        } catch (error) {
            setErr(error.response?.data?.message || "Помилка при збереженні конфігурації");
        }
    };

    const handleDelete = async () => {
        try {
            await apiClient.delete(`/api/configs/${deleteKey}`);
            setConfigs((prevConfigs) => prevConfigs.filter((config) => config.key !== deleteKey));
            setShowModal(false);
            setDeleteKey(null);
        } catch (error) {
            setErr(error.response?.data?.message || "Помилка при видаленні конфігурації");
        }
    };

    const openDeleteModal = (key) => {
        setDeleteKey(key);
        setShowModal(true);
    };

    const closeDeleteModal = () => {
        setShowModal(false);
        setDeleteKey(null);
    };

    const handleEdit = (config) => {
        setKey(config.key);
        setValue(config.value);
        setIsEditing(true);
        setIsFormVisible(true);
    };

    const handleAddNew = () => {
        setKey("");
        setValue("");
        setIsEditing(false);
        setIsFormVisible(true);
    };

    const handleCancel = () => {
        setKey("");
        setValue("");
        setIsEditing(false);
        setIsFormVisible(false);
    };

    return (
        <div className="container">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2>Налаштування</h2>
                {!isFormVisible ? (
                    <button className="btn btn-primary" onClick={handleAddNew}>
                        Додати нову конфігурацію
                    </button>
                ) : null}
            </div>
            {msg && <div className="alert alert-success">{msg}</div>}
            {err && <div className="alert alert-danger">{err}</div>}
            {isFormVisible && (
                <form id="configForm">
                    <div>
                        <label>Ключ</label>
                        <input
                            type="text"
                            value={key}
                            onChange={(e) => setKey(e.target.value)}
                            disabled={isEditing}
                        />
                    </div>
                    <div>
                        <label>Значення</label>
                        <input
                            type="text"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </div>
                    <div>
                        <button onClick={handleSave} className="btn btn-primary">
                            {isEditing ? "Оновити" : "Зберегти"}
                        </button>
                    </div>
                    <div>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleCancel}
                        >
                            Скасувати
                        </button>
                    </div>
                </form>
            )}
            <h3>Список конфігурацій</h3>
            <ul className="list">
                {configs.map((config) => (
                    <li
                        key={config.key}
                        className="config-item"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                        }}
                    >
                        <div className="config-content">
                            <div className="config-key-value">
                                <strong>{config.key}:</strong> {config.value}
                            </div>
                            {CONFIG_DESCRIPTIONS[config.key] && (
                                <div className="config-description" style={{ 
                                    fontSize: "0.7em",
                                    color: "#666",
                                    marginTop: "4px",
                                    whiteSpace: "pre-line",
                                    fontFamily: "monospace"
                                }}>
                                    {CONFIG_DESCRIPTIONS[config.key]}
                                </div>
                            )}
                        </div>
                        <div className="config-actions">
                            <FaEdit
                                style={{ 
                                    cursor: 'pointer', 
                                    color: 'blue', 
                                    fontSize: "1.5em"
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleEdit(config);
                                }}
                            />
                            {config?.isDeletable && (
                                <FaRegTrashAlt
                                    style={{ 
                                        cursor: 'pointer', 
                                        color: 'red', 
                                        fontSize: "1.5em",
                                        marginLeft: "10px" 
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openDeleteModal(config.key)
                                    }}
                                />
                            )}
                        </div>
                    </li>
                ))}
            </ul>

            {/* Модальне вікно підтвердження видалення */}
            <Modal show={showModal} onHide={closeDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Підтвердження видалення</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Ви впевнені, що хочете видалити ключ "{deleteKey}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteModal}>
                        Скасувати
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Видалити
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ConfigPage;
