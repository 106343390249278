import React, { useState } from 'react';
import CoursesList from './CoursesList';
import CourseForm from './CourseForm';

const CoursesPage = () => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [editingCourse, setEditingCourse] = useState(null);
    const [refreshCourses, setRefreshCourses] = useState(0);

    const handleSave = () => {
        setEditingCourse(null);
        setIsFormVisible(false);
        setRefreshCourses(prev => prev + 1);
    };

    const handleEdit = (course) => {
        setEditingCourse(course);
        setIsFormVisible(true);
        setRefreshCourses(prev => prev + 1);
    };

    const handleAddNew = () => {
        setEditingCourse(null);
        setIsFormVisible(true);
    };

    const handleDuplicate = (course) => {
        const duplicatedCourse = {
            ...course,
            id: '',
            course_id: '',
            price: '',
        };
        setEditingCourse(duplicatedCourse);
        setIsFormVisible(true);
    };

    const handleCancel = () => {
        setEditingCourse(null);
        setIsFormVisible(false);
    };

    return (
        <div className="container">
            <h2>Курси</h2>
            {isFormVisible && (
                <CourseForm
                    course={editingCourse}
                    onSave={handleSave}
                    onCancel={handleCancel}
                />
            )}
            <CoursesList 
                onEdit={handleEdit} 
                onDuplicate={handleDuplicate} 
                refreshCourses={refreshCourses}
                handleAddNew={handleAddNew}
            />
        </div>
    );
};

export default CoursesPage;
