import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import dateFormat from "../../constants/dateFormat"
import apiClient from "../../api";
import { FaClipboard, FaCog } from "react-icons/fa";

const TransactionDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [transaction, setTransaction] = useState(null);
    const [courses, setCourses] = useState([]);
    const [err, setErr] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState({
        metadata: {},
        action: '',
        course_id: null
    });
    const [newMetadataField, setNewMetadataField] = useState({ key: '', value: '' });
    const [template, setTemplate] = useState(null);

    useEffect(() => {
        const fetchTransactionDetails = async () => {
            try {
                const response = await apiClient.get(`/api/transactions/${id}`);
                setTransaction(response.data);
                setEditedData({
                    metadata: response.data.metadata,
                    action: response.data.action,
                    course_id: response.data.course_id
                });

                // Якщо є course_id, завантажуємо інформацію про шаблон
                if (response.data.course_id) {
                    try {
                        const templateResponse = await apiClient.get(`/api/templates/by-course/${response.data.course_id}`);
                        setTemplate(templateResponse.data);
                    } catch (error) {
                        console.error("Помилка при завантаженні шаблону:", error);
                    }
                }
            } catch (error) {
                setErr(error.response?.data?.message || "Не вдалося завантажити транзакцію");
            }
        };

        const fetchCourses = async () => {
            try {
                const response = await apiClient.get("/api/courses");
                setCourses(response.data);
            } catch (error) {
                console.error("Помилка при завантаженні курсів:", error);
            }
        };

        fetchTransactionDetails();
        fetchCourses();
    }, [id]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedData({
            metadata: transaction.metadata,
            action: transaction.action,
            course_id: transaction.course_id
        });
    };

    const handleSave = async () => {
        try {
            const response = await apiClient.put(`/api/transactions/${id}`, editedData);
            setTransaction(response.data);
            setIsEditing(false);
            setErr("");

            // Оновлюємо інформацію про шаблон, якщо змінився course_id
            if (response.data.course_id) {
                try {
                    const templateResponse = await apiClient.get(`/api/templates/by-course/${response.data.course_id}`);
                    setTemplate(templateResponse.data);
                } catch (error) {
                    console.error("Помилка при завантаженні шаблону:", error);
                    setTemplate(null);
                }
            } else {
                // Якщо course_id був видалений, очищаємо дані про шаблон
                setTemplate(null);
            }
        } catch (error) {
            setErr(error.response?.data?.message || "Помилка при збереженні змін");
        }
    };

    const handleMetadataChange = (key, value) => {
        setEditedData(prev => ({
            ...prev,
            metadata: {
                ...prev.metadata,
                [key]: value
            }
        }));
    };

    const handleAddMetadataField = () => {
        if (newMetadataField.key.trim()) {
            setEditedData(prev => ({
                ...prev,
                metadata: {
                    ...prev.metadata,
                    [newMetadataField.key]: newMetadataField.value
                }
            }));
            setNewMetadataField({ key: '', value: '' }); // Очищаємо поля після додавання
        }
    };

    const handleRemoveMetadataField = (keyToRemove) => {
        setEditedData(prev => {
            const newMetadata = { ...prev.metadata };
            delete newMetadata[keyToRemove];
            return { ...prev, metadata: newMetadata };
        });
    };

    const handleCourseChange = async (courseId) => {
        setEditedData(prev => ({
            ...prev, 
            course_id: courseId ? Number(courseId) : null
        }));

        // Оновлюємо інформацію про шаблон при зміні курсу
        if (courseId) {
            try {
                const templateResponse = await apiClient.get(`/api/templates/by-course/${courseId}`);
                setTemplate(templateResponse.data);
            } catch (error) {
                console.error("Помилка при завантаженні шаблону:", error);
                setTemplate(null);
            }
        } else {
            setTemplate(null);
        }
    };

    const getColorByAction = (item) => {
        switch (item?.action) {
            case 'Waiting':
                return 'blue';
            case 'Blocked':
                return 'red';
            default:
                return 'green';
            }
    }

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(transaction.stripePaymentId)
            .then(() => alert('Transaction ID скопійовано!'))
            .catch(err => console.error('Помилка при копіюванні:', err));
    };

    const handleSetConfig = async () => {
        try {
            await apiClient.put('/api/configs', {
                key: 'ending_before',
                value: transaction.stripePaymentId
            });
            alert('Config оновився!');
        } catch (error) {
            console.error('Помилка при оновленні config:', error);
            setErr(error.response?.data?.message || "Не вдалося оновити config");
        }
    };

    if (!transaction) return <p>Завантаження...</p>;

    const getCourseDetail = (item) => {
        const course = courses.find(c => c.id === item.course_id);
        return course ? `${course.title}: ${course.language} - ${course.price} - ${course.course_id}` : 'Не вдалося завантажити курс';
    }

    return (
        <div className="container">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <button onClick={() => navigate(-1)} className="btn btn-outline-primary btn-xs">
                    Назад
                </button>
                
                {!isEditing ? (
                    <button onClick={handleEdit} className="btn btn-primary">
                        Редагувати
                    </button>
                ) : (
                    <div>
                        <button onClick={handleSave} className="btn btn-success me-2">
                            Зберегти
                        </button>
                        <button onClick={handleCancel} className="btn btn-secondary">
                            Скасувати
                        </button>
                    </div>
                )}
            </div>
            
            {err && <div className="alert alert-danger">{err}</div>}

            <h2>Деталі транзакції</h2>

            {/* Незмінні поля */}
            <p><strong>ID:</strong> {transaction.id}</p>
            <p>
                <strong>Transaction ID:</strong> {transaction.stripePaymentId}
                <button
                    onClick={handleCopyToClipboard}
                    className="btn btn-sm ms-4 mb-2"
                    type="button"
                    title="Копіювати Transaction ID"
                    style={{ cursor: 'pointer', fontSize: "1em", lineHeight: "1em" }}
                >
                    <FaClipboard />
                </button>
                <button
                    onClick={handleSetConfig}
                    className="btn btn-sm ms-2 mb-2"
                    type="button"
                    title="Встановити значення в ending_before у Config"
                    style={{ cursor: 'pointer', fontSize: "1em", lineHeight: "1em" }}
                >
                    <FaCog />
                </button>
            </p>
            <p><strong>Час оплати:</strong> {dateFormat(transaction.createdAt)}</p>
            <p><strong>Сума:</strong> {transaction.amount/100} {transaction.currency}</p>
            <p><strong>Статус:</strong> {transaction.status}</p>

            {/* Поля, що редагуються */}
            <div className="mb-3">
                <strong>Action:</strong>
                {isEditing ? (
                    <select 
                        className="form-select"
                        value={editedData.action}
                        onChange={(e) => setEditedData(prev => ({...prev, action: e.target.value}))}
                    >
                        <option value="Waiting">Waiting</option>
                        <option value="Sent">Sent</option>
                        <option value="Blocked">Blocked</option>
                    </select>
                ) : (
                    <p style={{ color: `${getColorByAction(transaction)}` }}>{transaction.action}</p>
                )}
            </div>

            <div className="mb-3">
                <strong>Course:</strong>
                {isEditing ? (
                    <select 
                        className="form-select"
                        value={editedData.course_id || ''}
                        onChange={(e) => handleCourseChange(e.target.value)}
                    >
                        <option value="">Не вибрано</option>
                        {courses.map(course => (
                            <option key={course.id} value={course.id}>
                                {course.title}: {course.language} - {course.price} - {course.course_id} 
                            </option>
                        ))}
                    </select>
                ) : (
                    <p>{getCourseDetail(transaction)}</p>
                )}
            </div>

            <div className="mb-3">
                <strong>Metadata:</strong>
                {isEditing ? (
                    <div className="card p-3">
                        {Object.entries(editedData.metadata || {}).map(([key, value]) => (
                            <div key={key} className="mb-2 d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <label className="form-label">{key}:</label>
                                    <div className="d-flex gap-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={value}
                                            onChange={(e) => handleMetadataChange(key, e.target.value)}
                                        />
                                        <button 
                                            className="btn btn-danger"
                                            onClick={() => handleRemoveMetadataField(key)}
                                            type="button"
                                        >
                                            Видалити
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {/* Форма для додавання нового поля */}
                        <div className="mt-3 p-2 border rounded">
                            <h6>Додати нове поле</h6>
                            <div className="mb-2">
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    placeholder="Назва поля"
                                    value={newMetadataField.key}
                                    onChange={(e) => setNewMetadataField(prev => ({ ...prev, key: e.target.value }))}
                                />
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    placeholder="Значення"
                                    value={newMetadataField.value}
                                    onChange={(e) => setNewMetadataField(prev => ({ ...prev, value: e.target.value }))}
                                />
                                <button 
                                    className="btn btn-success w-100"
                                    onClick={handleAddMetadataField}
                                    type="button"
                                >
                                    Додати поле
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <pre>{JSON.stringify(transaction.metadata, null, 2)}</pre>
                )}
            </div>

            {/* Відображення інформації про шаблон */}
            <div className="mb-3">
                <strong>Template:</strong>
                {template ? (
                    <p>
                        <Link to={`/admin/templates/${template.id}`}>
                            {template.name} ({template.language})
                        </Link>
                    </p>
                ) : (
                    <p className="text-muted">Шаблон не знайдено</p>
                )}
            </div>

            {/* Відображення пов'язаних emails */}
            {transaction.emails && transaction.emails.length > 0 && (
                <div className="mb-3">
                    <strong>Emails:</strong>
                    <div className="list-group">
                        {transaction.emails.map(email => (
                            <Link 
                                key={email.id}
                                to={`/admin/emails/${email.id}`}
                                className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                            >
                                <div>
                                    <div>{email.subject}</div>
                                    <small className="text-muted">
                                        {dateFormat(email.createdAt)}
                                    </small>
                                </div>
                                <span className={`badge ${email.status === 'SENT' ? 'bg-success' : 'bg-warning'}`}>
                                    {email.status}
                                </span>
                            </Link>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TransactionDetails;
